import React, { Component } from 'react';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import './App.css';

class SalesSimulator extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cities: []
    }
  }

  async componentDidMount() {
    const apiUrl = 'https://hqs5dk03eb.execute-api.ca-central-1.amazonaws.com/demo/cities'
    const apiHeaders = new Headers({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'x-api-key': 'RCynf60PnA9hqN6I6zVmG58wKSGn64Ncc5EtdgX4'
    });
    const options = {
      method: 'GET',
      headers: apiHeaders
    }
    try {
      const response = await fetch(apiUrl, options);
      const data = await response.json();
      this.setState({ cities: data });
    } catch (err) {
      console.error('[City Load] Error loading cities', err);
    }
  }

  async simulateSale(city) {
    const apiUrl = 'https://hqs5dk03eb.execute-api.ca-central-1.amazonaws.com/demo/sales'
    const amount = Math.floor((Math.random() * 25) + 1);
    const payload = {
      "city_id": city.c_id,
      "quantity": amount,
      "item_sku": "test_item"
    };
    const apiHeaders = new Headers({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'x-api-key': 'RCynf60PnA9hqN6I6zVmG58wKSGn64Ncc5EtdgX4'
    });
    const options = {
      method: 'POST',
      headers: apiHeaders,
      body: JSON.stringify(payload)
    }
    try { 
      const response = await fetch(apiUrl, options);
      const result = await response.json();
      if (result.success) {
        alert(`Simulated a sale of ${amount} items in ${city.c_name}, ${city.c_state}, ${city.c_country}.`);
      }
    } catch(err) {
      console.error('[Sale Simulation] Error loading products', err);
    }
  };

  rednderCityList() {
    const { cities } = this.state;
    return cities.map(city => {
        return (
          <tr key={city.c_id}>
            <td>{city.c_name}</td>
            <td>{city.c_state}</td>
            <td>{city.c_country}</td>
            <td>
              <Button
                className="btn btn-primary btn-sm"
                onClick={() => this.simulateSale(city)}
              >
                Simulate Sale
              </Button>
            </td>
          </tr>
        );
    });
  }

  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <Table striped>
              <thead>
                <tr>
                  <th>City</th>
                  <th>Province</th>
                  <th>Country</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {this.rednderCityList()}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    );
  }
}

/**
App
**/
class App extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="App">
        <div className="App-header">
          <h2>Welcome to Octank Sales Simulator</h2>
        </div>
        <SalesSimulator></SalesSimulator>
      </div>
    );
  }
}

export default App;